.modebar-group {
    background-color: transparent !important;
}

.plotly-plot-div {
    border-color: white;
    border-style: solid;
    border-width: 1.5px;
    border-radius: .2857rem;
    margin-bottom: 10px;
    padding-top: 11px;
    padding-right: 7px;
    padding-bottom: 50px;
}

.load-generator-buttons {
    margin-top: 15px;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-around;
}

.load-generator-buttons .btn:first-child {
    
}

.load-generator-instructions {
    margin-left: 30px;
    margin-bottom: 20px;
}

.btn-loadgen {
    width: 70px;
}

.noUi-connect {
    background: $qantic-blue !important;
}

.noUi-target {
    border: none !important;
}

.noUi-horizontal {
    height: 3px !important;
}

.noUi-tooltip {
    display: none !important;
}
.noUi-active .noUi-tooltip {
    display: block !important;
}

.load-gen-slider-group {
    margin-bottom: 25px;
}

.slider-line-disabled {
    background-color: #1d253b !important;
}

.load-preview-buttons {
    margin-top: 15px;
}

.load-preview-buttons .btn:first-child {
    margin-left: 1px;
    margin-right: auto;
}

.input-file-upload-instructions {
    margin-top: 20px;
    margin-left: 30px;
    margin-right: 30px;
    margin-bottom: 20px;
}

.img-comp-container {
    position: relative;
    height: 120px; /*should be the same height as the images*/
}

.img-comp-img {
    position: absolute;
    width: auto;
    height: auto;
    overflow: hidden;
}

.img-comp-img img {
    display: block;
    vertical-align: middle;
}

.img-comp-slider {
    position: absolute;
    z-index: 9;
    cursor: ew-resize;
    /*set the appearance of the slider:*/
    background-color: $white;
    opacity: 0.6;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.slider-marker {
    margin-top: 120px;
    display: table;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: $white;
}

.stacked-area-plot-div {
    padding-bottom: 40px;
    padding-right: 7px;
    padding-left: 7px;
}

.stacked-area-chart-header {
    margin-bottom: 20px !important;
}

.plot-image {
    margin-bottom: 20px;
}

.selector-text {
    fill: black !important;
}

.plot-comment {
    margin-top: 15px;
    padding-left: 30px;
    padding-right: 30px;
}

#economic-performance-charts {
    padding-left: 35px;
    padding-right: 35px;
}

#project-location-map {
    height: 220px;
  }

#project-location-map.reactive-map {
    height: 230px;
  }

.wrappable-filename {
    overflow-wrap: anywhere !important;
}

.btn.btn-primary.btn-file {
    margin-left: 0px;
}

.btn-skinny {
    height: 20px;
    padding-top: 2px;
}

.plotly-plot-div.info-box {
    padding-bottom: 15px;
    margin-bottom: 20px;
}

.fileinput-filename.wrappable-filename {
    color: rgba(255, 255, 255, 0.8);
    margin-left: 10px;
}


.big-loading-spinner {
	width: 15em;
	height: 15em;
	margin-bottom: 60px;
	margin-top: 30px;
}

#login-form-reset-link {
    margin-top: 15px;
}

#login-form-test-account-link {
    margin-top: 10px;
}

.triple-plot {
    padding-bottom: 10px !important;
    padding-left: 0px !important;
    height: auto;
    max-height: 420px;
}


.download-plot-button {
	width: auto;
	padding-left: 7px;
	margin: 4px;
	text-align: center;
	padding-top: 4px;
	padding-bottom: 2px;
}

.result-highlight:not(:last-child) {
	border-right-style: solid;
	border-width: 1px;
	border-color: rgba(255, 255, 255, 0.1);
}

.tooltip-inner {
    min-width: 100px;
    max-width: 300px;
    text-align: justify;
}

svg.main-svg:last-child {
    overflow:visible !important;
    z-index: 1000 !important;
}

// .sankey-link { fill-opacity: 1; stroke-opacity: 1; }
