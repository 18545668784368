/* Form controls */
@include form-control-placeholder(#6c757c, 1);

.form-control {
  border-color: darken($dark-gray,20%);
  border-radius: $border-radius-lg;
  font-size: $font-size-sm;
  @include transition-input-focus-color();


  &:focus {
    border-color: $primary;
    background-color: $input-bg;
    @include box-shadow(none);

    & + .input-group-append .input-group-text,
    & ~ .input-group-append .input-group-text,
    & + .input-group-prepend .input-group-text,
    & ~ .input-group-prepend .input-group-text {
        border: 1px solid $primary;
        border-left: none;
        background-color: $transparent-bg;
    }
  }

  .has-success &,
  .has-error &,
  .has-success &:focus,
  .has-error &:focus {
      @include box-shadow(none);
  }

  .has-danger &,
  .has-success & {
      &.form-control-success,
      &.form-control-danger {
          background-image: none;
      }
  }

  & + .form-control-feedback {
      border-radius: $border-radius-lg;
      margin-top: -7px;
      position: absolute;
      right: 10px;
      top: 50%;
      vertical-align: middle;
  }

  .open & {
      border-radius: $border-radius-lg $border-radius-lg 0 0;
      border-bottom-color: $transparent-bg;
  }

  & + .input-group-append .input-group-text,
  & + .input-group-prepend .input-group-text {
      background-color: $white;
  }

}

.has-success .input-group-append .input-group-text,
.has-success .input-group-prepend .input-group-text,
.has-success .form-control {
    border-color: lighten($black,5%);
}

.has-success .form-control:focus,
.has-success.input-group-focus .input-group-append .input-group-text,
.has-success.input-group-focus .input-group-prepend .input-group-text {
  border-color: darken($success, 10%);
}

.has-danger .form-control,
.has-danger .input-group-append .input-group-text,
.has-danger .input-group-prepend .input-group-text,
.has-danger.input-group-focus .input-group-prepend .input-group-text,
.has-danger.input-group-focus .input-group-append .input-group-text {
    border-color: lighten($danger-states,5%);
    color: $danger-states;
    background-color: rgba(222,222,222, .1);

    &:focus {
        background-color: $transparent-bg;
    }
}

.has-success,
.has-danger {
    &:after {
        font-family: 'nucleo';
        content: "\ea1b";
        display: inline-block;
        position: absolute;
        right: 20px;
        top: 13px;
        color: $success;
        font-size: 11px;
    }

    &.form-control-lg {
      &:after {
        font-size: 13px;
        top: 24px;
      }
    }

    &.has-label {
      &:after{
        top: 37px;
      }
    }


    &.form-check:after {
      display: none !important;
    }

    &.form-check .form-check-label{
      color: $success;
    }
}

.has-danger {
  &:after{
    content: "\ea48";
    color: $danger-states;
  }

  &.form-check .form-check-label {
    color: $danger-states;
  }

  &.input-group-focus {
    .input-group-text {
      border-color: #ec250d !important;
    }
  }
}


@include media-breakpoint-down(xs) {
  .form-horizontal .col-form-label,
  .form-horizontal .label-on-right {
    text-align: inherit;
    padding-top: 0;
    code {
      padding: 0 10px;
    }
  }
}


// Hide arrows on number fields
// Chrome, Safari, Edge, Opera
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
// Firefox
input[type=number] {
  -moz-appearance: textfield;
}

.form-horizontal .row .col-form-label {
  max-width: 350px;
}

option {
  color: $black;
}

.formset-row-header {
  margin-bottom: 5px;
}

.formset-row-fields {
  margin-bottom: 10px;
}

.form-inline-general-fields {
  display: flex;
  flex-flow: row wrap;
  align-items: center; // Prevent shorter elements from growing to same height as others (e.g., small buttons growing to normal sized button height)

  // Because we use flex, the initial sizing of checkboxes is collapsed and
  // doesn't occupy the full-width (which is what we want for xs grid tier),
  // so we force that here.
  .form-check {
    width: 100%;
  }

  // Kick in the inline
  @include media-breakpoint-up(sm) {
    label {
      // display: flex;
      // align-items: center;
      // justify-content: center;
      // margin-bottom: 0;
    }

    // Inline-block all the things for "inline"
    .form-group {
      display: flex;
      flex: 0 0 auto;
      flex-flow: row wrap;
      align-items: center;
      margin-bottom: 0;
    }

    // Allow folks to *not* use `.form-group`
    .form-control {
      display: inline-block;
      width: auto; // Prevent labels from stacking above inputs in `.form-group`
      vertical-align: middle;
    }

    // Make static controls behave like regular ones
    .form-control-plaintext {
      display: inline-block;
    }

    .input-group,
    .custom-select {
      width: auto;
    }

    // Remove default margin on radios/checkboxes that were used for stacking, and
    // then undo the floating of radios and checkboxes to match.
    .form-check {
      display: flex;
      align-items: center;
      justify-content: center;
      width: auto;
      padding-left: 0;
    }
    .form-check-input {
      position: relative;
      flex-shrink: 0;
      margin-top: 0;
      margin-right: $form-check-input-margin-x;
      margin-left: 0;
    }

    .custom-control {
      align-items: center;
      justify-content: center;
    }
    .custom-control-label {
      margin-bottom: 0;
    }
  }
}

.input-field-error {
  color: red !important;
  border-color: red !important;
}

.form-control .input-field-error:focus {
  color: red !important;
  border-color: red !important;
}
